import React, { useState , useEffect } from 'react';
import ReactPlayer from 'react-player';
import './css/lessonSidebar.css';
import { Link as RouterLink } from 'react-router-dom';
import { BiVideo } from 'react-icons/bi'; 
import { CiClock2 } from 'react-icons/ci';
import axios from '../config/index';

const LessonSidebar = ({ lessons, currentLessonId, courseId, courseHeader  ,  activeLessonId,
    handleLessonClick}) => {
    const [durations, setDurations] = useState({});
    // const [userId, setUserId] = useState();
    // const [activeLessonId, setActiveLessonId] = useState(currentLessonId);
    const [courseheader, setCourseHeader] = useState(courseHeader);


// console.log("userID" , userId); 
console.log("current",  activeLessonId)



// const saveLesson = (lessonId) => {
//     if (userId) {
//         axios
//             .put('/current-lesson', { userId, lessonId, courseId })
//             .then((res) => {
//                 console.log('Lesson saved successfully:', res.data);
//             })
//             .catch((err) => console.error('Error saving lesson:', err));
//     }
// };


// const handleLessonClick = (lessonId) => {
//     setActiveLessonId(lessonId);
//     saveLesson(lessonId);
// };   
    const handleDuration = (lessonId, duration) => {
        setDurations((prev) => ({ ...prev, [lessonId]: formatDuration(duration) }));
    };

    const formatDuration = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <div className="lesson-sidebar">
            <h2 className="course-header">{courseheader}</h2>
            <ul>
                {lessons.map((lesson) => (
                     <li
                     key={lesson.id}
                     className={`lesson-item ${lesson.id === activeLessonId ? 'active' : ''}`}
                     onClick={() => handleLessonClick(lesson.id)}
                 >
                        <RouterLink
                            to={`/lesson?id=${lesson.id}&course_id=${courseId}`}
                            className="lesson-link"
                        >
                            {lesson.header} <BiVideo className="icon" />
                            <ReactPlayer
                                url={`./uploads/${lesson.video}`}
                                width="0"
                                height="0"
                                onDuration={(duration) => handleDuration(lesson.id, duration)}
                                 
                            />
                        {durations[lesson.id] ? (
                             <span className="lesson-duration">
                             <CiClock2 className="icon-clock" /> {durations[lesson.id]}
                         </span>
                        ) : (
                            <span className="lesson-duration loading">
                                {/* جاري التحميل... */}
                                <CiClock2 className="icon-clock" />  00:00
                            </span>
                        )}
                        </RouterLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default LessonSidebar;
