import './css/instdash.css';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import BooksImages from './images/books.webp';
import courseDefault from './images/courses/courseImgDef.jpg'
import axios from '../config/index';
import { useState, useEffect } from 'react';

function StudentDash() {
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const [user, setUser] = useState([]);
    const [activeLessonId, setActiveLessonId] = useState();
    const [courses, setCourses] = useState([]);
    const [userCourses, setUserCourses] = useState([]);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [courseLessonsMap, setCourseLessonsMap] = useState({});
    // const [filteredCourses, setFilteredCourses] = useState([]);
    const [lessons, setLessons] = useState([]);

    useEffect(() => {
        axios.get('/session')
            .then((res) => {
                if (res.data.valid && res.data.type === "user") {
                    setUserId(res.data.userId);
                } else {
                    navigate("/");
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (userId) {
            const itemId = userId;
            axios.post('/get-user', { itemId })
                .then((res) => setUser(res.data))
                .catch((err) => console.log(err));
        }
    }, [userId]);


    useEffect(() => {
        axios.post('/AllItems', { table: 'courses' })
            .then((res) => {
                if (res.data) {
                    setCourses(res.data);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (userId) {
            axios.post('/AllItems', { table: 'user_courses' })
                .then((res) => {
                    if (res.data) {
                        const userCourseIds = res.data.filter(uc => uc.user_id === userId).map(uc => uc.course_id);
                        const userCurrentLessonsIds = res.data.filter(uc => uc.user_id === userId).map(uc => uc.current_lesson_id);
                        setUserCourses(userCourseIds);
                        console.log(res.data,'user');
                        setCurrentLesson(userCurrentLessonsIds);
                        console.log(userCurrentLessonsIds, "userCurrentLessons")
                    } else {
                        console.log("Error happened");
                    }
                })
                .catch((err) => console.log(err));
        }
    }, [userId]);

    useEffect(() => {
        axios.post('/AllItems', { table: 'lessons' })
            .then((res) => {
                if (res.data) {
                    setLessons(res.data);
                      // Create a map where each course_id maps to its lessons
                      const lessonsMap = res.data.reduce((acc, lesson) => {
                        acc[lesson.course_id] = acc[lesson.course_id] || [];
                        acc[lesson.course_id].push(lesson);
                        return acc;
                    }, {});
                    setCourseLessonsMap(lessonsMap);
                } else {
                    console.log("Error happened");
                }
            })
            .catch((err) => console.log(err));
            
    }, []);
    const filteredCourses = courses
    .filter(course => userCourses.includes(course.id))
    .map(course => {
        const courseLessons = courseLessonsMap[course.id] || [];
        const firstLessonId = courseLessons.length ? courseLessons[0].id : null;
        return {
            ...course,
            firstLessonId,
            lessonCount: courseLessons.length,
            courseLessons
        };
    });
    // useEffect(() => {
    //     if (courses.length && userCourses.length && lessons.length && currentLesson) {
    //         const coursesWithLessons = courses.map(course => {
    //             const courseLessons = lessons.filter(lesson => lesson.course_id === course.id);
    //             setCourseLessons(courseLessons);
    // console.log("Course Lessons:", courseLessons);
   
    //             const firstLessonId = courseLessons.length ? courseLessons[0].id : null;
    
    //             console.log(firstLessonId, "firstLess");
    //             return { ...course, firstLessonId, lessonCount: courseLessons.length , courseLessons };
    //         });
    //         const filtered = coursesWithLessons.filter(course => userCourses.includes(course.id));
    //         setFilteredCourses(filtered);
    //     }
    // }, [courses, userCourses, lessons ]);
    console.log("Lessons:", lessons);
    console.log("Current Lesson ID:", currentLesson);

    return (
        <>
            <section className='inst-dash'>
                <div className='dash-header'>
                    <div className='inst-header'>
                        <div className='background-colored'></div>
                        <div className='user-side-nav'>
                            <ul>
                                <li>
                                    <RouterLink to="/user-settings">  <i className="las la-cog"></i></RouterLink>
                                </li>
                            </ul>
                        </div>
                        <div className='inst-card'>
                            <img src={BooksImages} alt='books' />
                            <h2> طالب في مييد </h2>
                            <h1> {user.fullname} </h1>
                            <div className='info'>
                                <img src={`./uploads/${user.profile_img}`} alt='instructor image' />
                                <div className='info-data'></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='user-dashboard'>
                    <div className='container'>
                        <div class="row d-flex justify-content-center align-items-center">
                            <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                                <div className='dash-content'>
                                    <h2> الكورسات التي تم شرائها </h2>
                                    <div className='int-courses'>
                                        <div className="row">
                                            {currentLesson && filteredCourses.map((course, index) => (
                                                <div className='col-lg-6 col-md-6 col-sm-12 col-12' key={index}>
                                                    <div className='course-card'>
                                                        <h1>{course.header}</h1>
                                                        <div className='instructor-info'>
                                                            <img src={`./uploads/${user.profile_img}`} alt='instructor image' />
                                                            <h4>بواسطة <span>{user.fullname}</span></h4>
                                                        </div>
                                                        <RouterLink to={`/lesson?id=${currentLesson[index] ? currentLesson[index] : course.firstLessonId}&course_id=${course.id}`} >
                                                            <img
                                                                //  src={`./uploads/${course.img}` } 
                                                                src={courseDefault}
                                                                alt='course image' />
                                                        </RouterLink>
                                                        <div className="progress-info">
    <span className="progress-percentage">
        %{" "}
        {currentLesson[index]
            ? Math.round(
                  ((course.courseLessons.findIndex(
                      (lesson) => lesson.id === currentLesson[index]
                  ) + 1) /
                      course.lessonCount) *
                      100
              )
            : 0}
    </span>
    <div className="progress">
        <div
            className="progress-bar"
            role="progressbar"
            style={{
                width: `${
                    currentLesson[index]
                        ? ((course.courseLessons.findIndex(
                              (lesson) => lesson.id === currentLesson[index]
                          ) + 1) /
                              course.lessonCount) *
                          100
                        : 0
                }%`,
                direction: "rtl", // Make progress bar go from right to left
            }}
            aria-valuenow={
                currentLesson[index]
                    ? Math.round(
                          ((course.courseLessons.findIndex(
                              (lesson) => lesson.id === currentLesson[index]
                          ) + 1) /
                              course.lessonCount) *
                              100
                      )
                    : 0
            }
            aria-valuemin="0"
            aria-valuemax="100"
        ></div>
    </div>
</div>

                                                        <div className='text'>
                                                            {/* <div className='reviews'>
                                                                <ul>
                                                                    {Array(course.rate || 5).fill(null).map((_, i) => (
                                                                        <li key={i}>
                                                                            <i className="las la-star"></i>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                                <span>(مراجعات)</span>
                                                            </div> */}
                                                            <div className='course-info'>

                                                                {/* <span><i className="las la-users"></i> {course.students} طلاب</span> */}
                                                            </div>
                                                            {/* <p className='section-text'>{course.description}</p> */}

                                                            <div className='course-price'>
                                                                {/* {course.free === 1 ? <h3> مجاني </h3> : (
                                                                    <>
                                                                        <h3>${course.price} <span>${course.prev_price}</span></h3>
                                                                    </>
                                                                )} */}
                                                                <span className="lesson-count">
                                                                    <i className="las la-book"></i>

                                                                    {currentLesson[index]

                                                                        ? course.courseLessons.findIndex((lesson) => lesson.id === currentLesson[index]) + 1
                                                                        : 0}
                                                                    / {course.lessonCount} دروس
                                                                </span>
                                                                <RouterLink to={`/lesson?id=${currentLesson[index] ? currentLesson[index] : course.firstLessonId}&course_id=${course.id}&header=${encodeURIComponent(course.header)}`}>اعرف أكثر <i className="las la-arrow-right"></i></RouterLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default StudentDash;
